/* You can add global styles to this file, and also import other style files */
/*@import 'https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css';*/

html, body { height: 100%; }
body { 
    margin: 0; 
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.bg_light_yellow {
    background-color: rgb(247 , 236, 169, 0.2);
}
.bg_light_green {
    background-color: rgb(218, 247, 166);
}
.bg_orange {
    background-color: rgb(255, 87, 51);
}

.control-full-width {
    width: 100%;
}

.mb_maxwidth {
    max-width: 768px;
}

/* Hide Arrows From Input Number - Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide Arrows From Input Number - Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.center_spinner {
    position: fixed;
    z-index: 999;
    height: 2rem;
    width: 2rem;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .uppercase {
    text-transform: uppercase;
  }